//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { title } from '@/settings';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true } },


  data: function data() {
    return {
      title: title,
      logo: require('@/assets/imgs/logo.png') };

  } };